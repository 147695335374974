<template>
  <div class="row"></div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  created: function () {},
};
</script>
